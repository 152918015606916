<div class="grid" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
[ngClass]="{'coproduction': isCoproduction, 'dark': theme === 'DarkMode', 'my-films': isMyFilmsEnabled}">

  <ng-container *ngIf="films.length > 0">
    <fspro-film-card
      *ngFor="let film of films"
      [film]="film"
      [titleInPoster]="false"
      [displayRemoveButton]="false"
      [isAdmin]="isAdmin"
      [borderBottom]="!isMyFilmsEnabled"
      [isAllowToShare]="isAllowToShare"
      (removeFromWatchListEmitter)="removeFromWatchList($event)"
      [isCoproduction]="isCoproduction"
      [theme]="theme"
      [isHiddenDirectorName]="isHiddenDirectorName"
      [isMyFilmsEnabled]="isMyFilmsEnabled"
    ></fspro-film-card>
  </ng-container>
  <ng-container *ngIf="!loading && films.length === 0 && isMyFilmsEnabled">
    <div class="no-record-message">
      <span>{{ 'fspro.rh.myfilms.no_results' | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <fspro-skeleton-film-card *ngFor="let i of [].constructor(this.films.length === 0 ? 6 : this.films.length % 3 === 0 ? 3 : 1)"></fspro-skeleton-film-card>
  </ng-container>
</div>
