import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilmBookingService } from '../shared/services/film-booking.service';
import { ScreenRequestService } from '../shared/services/screen-request.service';
import { FilmService } from '../shared/services/film.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsFacade {

  private _pendingScreeningRequest = new BehaviorSubject<number>(0);
  private _pendingBookingRequestRequest = new BehaviorSubject<number>(0);
  private _filmsAvailableForInvitation = new BehaviorSubject<number>(0);

  constructor(
    private readonly _screenRequestService: ScreenRequestService,
    private readonly _filmBookingService: FilmBookingService,
    private readonly _filmService: FilmService
  ) { }

  get pendingScreeningRequest$(): Observable<number> {
    return this._pendingScreeningRequest.asObservable();
  }

  get pendingBookingRequestRequest$(): Observable<number> {
    return this._pendingBookingRequestRequest.asObservable();
  }

  get filmsAvailableForInvitation$(): Observable<number> {
    return this._filmsAvailableForInvitation.asObservable();
  }


  public loadPendingScreeningRequest(): void {
    this._screenRequestService.countPendingScreeningRequests().subscribe((count) => this._pendingScreeningRequest.next(count));
  }

  public loadPendingBookingRequest(): void {
    this._filmBookingService.getPendingRequest().subscribe((count)=> this._pendingBookingRequestRequest.next(count));
  }

  public loadFilmAvailableForInvitation(): void {
    this._filmService.getFilmCountForRhlu().subscribe((count) => this._filmsAvailableForInvitation.next(count));
  }

}
