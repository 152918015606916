import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { IAvailabilityWrapperDto } from '../dtos/availability-wrapper-dto';
import { AuthenticationService } from 'src/app/authentications/authentication.service';

@Injectable()
export class FilmHelperService {

  constructor(private translateService: TranslateService, private datePipe: DatePipe, private authenticationService: AuthenticationService) { }

  public computeRestrictionMessage(availabilityWrapper: IAvailabilityWrapperDto, isComingSoon: boolean, isExpired: boolean): string {

    let message = null;

    let translatedMessage = null;

    if (availabilityWrapper) {
      if (availabilityWrapper?.canWatchFilm) {
        let messages = [];
        if (availabilityWrapper?.isHidden) {
          if (this.authenticationService.isAdmin || availabilityWrapper.isRHForFilm) {
            messages.push('fspro.front.film.availability_message.hidden');
          }
        }
        if (isComingSoon) {
          messages.push('fspro.front.film.availability_message.coming_soon');
        }

        if (isExpired) {
          if (this.authenticationService.isAdmin || availabilityWrapper.isRHForFilm) {
            messages.push('fspro.front.film.availability_message.expired');
          }
        }

        messages.forEach((message, index) => {
          if (index > 0) {
            translatedMessage += '<br>' + this.translateService.instant(message);
          } else {
            translatedMessage = this.translateService.instant(message);
          }
        })

        if (availabilityWrapper?.isPublished && availabilityWrapper?.publishedAt) {
          const now = new Date();
          const publicationDate = new Date(availabilityWrapper?.publishedAt);
          if (now < publicationDate) {
            const availableOnMessage = 'Available on ' + this.datePipe.transform(availabilityWrapper?.publishedAt, 'dd MMMM yyyy HH:mm', null, 'en-US') + ' GMT+1';
            if (translatedMessage === null) {
              translatedMessage = availableOnMessage;
            } else {
              translatedMessage += '<br>' + availableOnMessage;
            }
          }
        }
        if (availabilityWrapper?.isPublished && availabilityWrapper?.expiredAt) {
          const expirationDate = new Date(availabilityWrapper?.expiredAt);
          const now = new Date();
          if (now < expirationDate) {
            const availableUntilMessage = 'Available until ' + this.datePipe.transform(availabilityWrapper?.expiredAt, 'dd MMMM yyyy HH:mm', null, 'en-US') + ' GMT+1';

            if (translatedMessage === null) {
              translatedMessage = availableUntilMessage;
            } else {
              translatedMessage += '<br>' + availableUntilMessage;
            }
          }
        }
      } else {
        if (!availabilityWrapper?.isLoggedIn) {
          message = 'fspro.front.film.availability_message.login';
        } else if (availabilityWrapper?.isExpired) {
          message = 'fspro.front.film.availability_message.expired';
        } else if (availabilityWrapper?.isCategoryBlocked) {
          message = 'fspro.front.film.availability_message.professional_category';
        } else if (availabilityWrapper?.isGeoBlocked) {
            message = 'fspro.front.film.availability_message.geoblocked';
        } else if (availabilityWrapper?.hasInvitationExpired) {
          message = 'fspro.front.film.availability_message.invitation';
        } else if (availabilityWrapper?.isScreeningRequestDenied) {
          message = 'fspro.front.film.availability_message.request_denied';
        } else if (!availabilityWrapper?.hasMembership) {
          message = 'fspro.front.film.availability_message.membership';
        }
      }
      if (message !== null) {
        translatedMessage = this.translateService.instant(message);
      }
    }
    return translatedMessage;
  }
}
