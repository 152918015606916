<a *ngIf="collectionItem" [href]="collectionUrl">
  <div class="collection-item-wrapper" [ngClass]="{'highlight': isHighlight}">
    <div class="card">
      <div class="image-container">
        <img class="collection-item-logo img-fit-contain" [appCacheImage]="collectionItem.logoInPageUrl" [alt]="collectionItem.name">
      </div>
      <div class="hover-content" [ngClass]="{'label': collectionItem.type === 'label', 'lineup': collectionItem.type === 'lineup'}">
        <ng-container *ngIf="!isHighlight || !collectionItem.gifUrl">
          <h2 class="title">{{ collectionItem.name }}</h2>
          <p class="description" markdown *ngIf="collectionItem.shortDescription">{{ collectionItem.shortDescription }}</p>
        </ng-container>
        <ng-container *ngIf="isHighlight && collectionItem.gifUrl">
          <img [appCacheImage]="collectionItem.gifUrl" alt="highlight">
        </ng-container>
      </div>
    </div>
  </div>
</a>
